export default {
  tableColumnModule: () => [
    {
      title: "战备体检",
      dataIndex: "module1"
    },
    {
      title: "扫雷尖兵",
      dataIndex: "module2"
    },
    {
      title: "反恐精英",
      dataIndex: "module3"
    },
    {
      title: "沙漠行动",
      dataIndex: "module4"
    },
    {
      title: "丛林追击",
      dataIndex: "module5"
    },
    {
      title: "战舰出海",
      dataIndex: "module6"
    },
    {
      title: "抢滩登陆",
      dataIndex: "module7"
    },
    {
      title: "通关",
      dataIndex: "allPassed"
    }
  ],
  roles: {
    Principal: "Principal", // 校长
    ClassManager: "ClassManager", // 班主任
    MathTeacher: "MathTeacher", // 数学老师
    Parents: "Parents" // 家长
  }
};
