<template>
  <div id="login">
    <div id="login-content" style="position: relative">
      <div class="login-content-wrap">
        {{ schoolName }}
      </div>

      <div class="child-content">
        <div class="logo-wrap">
          <!-- <img src="../../assets/iconimg/logofont.png" alt="" /> -->
          <img src="../../assets/login/noEnglishLogo.png" alt="" />
        </div>

        <div
          style="display: flex; display: -webkit-flex; justify-content: center"
        >
          <div class="login-logo" style="">
            <div
              :class="selectState == 0 ? 'select-tab' : ''"
              style="cursor: pointer"
              @click="selectTab(0)"
            >
              中考冲刺
            </div>
            <div>
              <img
                v-if="selectState == 0"
                class="tab-img"
                src="../../assets/login/icon-xuanzhong@2x.png"
                alt=""
              />
            </div>
          </div>
          <div>
            <div
              :class="selectState == 1 ? 'select-tab' : ''"
              style="cursor: pointer"
              @click="selectTab(1)"
            >
              初中培优
            </div>
            <div>
              <img
                v-if="selectState == 1"
                class="tab-img"
                src="../../assets/login/icon-xuanzhong@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div style="text-align: left">
          <div class="input-title">用户名</div>
          <div class="user-input" style="display: flex; display: -webkit-flex">
            <input
              id="username"
              v-model.trim="username"
              type="text"
              placeholder="请输入 账号/学号/邮箱/手机号"
            />
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              display: -webkit-flex;
              justify-content: space-between;
            "
          >
            <span class="input-title">密码</span>
            <span style="font-size: 16px; color: #666666; cursor: pointer"
              >忘记密码?</span
            >
          </div>
          <div class="user-input">
            <input
              id="password"
              v-model.trim="password"
              type="password"
              @keyup.enter="toHome"
            />
          </div>
        </div>
        <div class="btn-wrap">
          <img
            id="login-button"
            src="../../assets/login/denglu-button@2x.png"
            alt=""
            @click="toHome"
          />
        </div>
      </div>
    </div>
    <GlobalPopup
      popupsTitle="服务器暂时繁忙，请稍后再试。。。"
      popupsbuttomB="我知道了"
      @OKbutton="
        () => {
         isLock = false
        }
      "
      :isShow="isLock"
    ></GlobalPopup>
  </div>
</template>

<script>
import { login, getUserProducts,fetchSchoolName } from "@/api/english-mock";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
// import { mapMutations } from "vuex";
import RoleUtils from "../../utils/roleUtil";
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      selectState: 0,
      userType: 0,
      username: "",
      password: "",
      schoolName: "",
      isLock:false,
    };
  },
  components:{
    GlobalPopup
  },
  async beforeRouteEnter(to, from, next) {
    console.log("路由拦截", from, to);
    console.log('token',to)
    sessionStorage.clear();
    if (to.query.token) {
      //要先缓存才能 调用接口
      store.commit("setUserToken", to.query.token);
      await getUserProducts().then(res => {
        console.log("测试跳转获取用户信息", res);
        if (
          !res.data.result.productsByUserPermissions ||
          res.data.result.productsByUserPermissions.length <= 0
        ) {
          this.$message.error("你的产品权限尚未开通，请联系培立优客服!");
          return false;
        }
        if (res.data.result.productsByUserPermissions[0]) {
          store.commit("setProduct", res.data.result.productsByUserPermissions[0]);
          store.commit("setPermission", res.data.result.userPermissionNames);
          //不要轻易改变，此处刷新是为了解决一些页面缓存问题
          window.location.href = `${window.location.origin}/ms3sprint/home`;
        }
      });
    }

    next();
  },
  // mounted: function () {
  //   this.showSchoolName();
  // },
  methods: {
    selectTab(state) {
      this.selectState = state;
    },
    toHome() {
      // debugger
      var subdomain = window.location.hostname.toLowerCase().split(".")[0];
      if (
        subdomain.indexOf("localhost") >= 0 ||
        "".includes.call(subdomain, "192")
      ) {
        subdomain = "dev"; //or other schools that you want to use
      }
      if (subdomain === "peiliyou") {
        subdomain = "";
      }

      let parameter = {
        userNameOrEmailAddress: this.username,
        password: this.password,
        tenancyName: subdomain,
        CheckProducts: 'english'
      };
      if (!this.username || !this.password || this.password.length < 6) {
        alert("用户名密码错误，请检查后再试！");
        return;
      }
      login(parameter).then(res => {
        console.log("登录信息", res);
        if(res.status === 209){
          this.isLock = true
          return
        }
        if(res.data.result.errorCode == 10020) {
          alert("找不到你的倍立优产品，请重新登录");
          return
        }
        if (
          res.data.result.errorCode == 1001 ||
          res.data.result.errorCode == 1002 ||
          res.data.result.errorCode == 10014 ||
          RoleUtils.isSchoolTerminal(res.data.result.customRoles)
        ) {
          alert("账户名或密码错误，请查证后再试");  
          return;
        }
        if (!res.data.result.accessToken) {
          alert("账户名或密码错误，请查证后再试");
          return;
        }
        if (
          !res.data.result.productsByUserPermissions ||
          res.data.result.productsByUserPermissions.length <= 0
        ) {
          alert("你的产品权限尚未开通，请联系培立优客服!");
          return;
        }
        this.$store.commit("setUserToken", res.data.result.accessToken);
        if (res.data.result.productsByUserPermissions[0]) {
          this.$store.commit(
            "setProduct",
            res.data.result.productsByUserPermissions[0]
          );
          this.$store.commit("setPermission", res.data.result.userPermissionNames);
        }
        if (RoleUtils.isSchoolTerminal(res.data.result.customRoles)) {
          // this.$message.error("教师账号请到教师端登录!");
          // window.location.href = `${window.location.origin}/school/home`;
          // this.$router.replace("/school/home");
        } else {
          this.$router.push("/ms3sprint/home/homeIndex");
          //不要轻易改变，此处刷新是为了解决一些页面缓存问题
          // window.location.href = `${window.location.origin}/ms3sprint`;
        }
      });
    },
    showSchoolName() {
      var url = window.location.href;
      fetchSchoolName({ tenancyName: url }).then(data => {
        const { success, result } = data;
        if (success && result) {
          this.schoolName = result;
        } else {
          this.schoolName = "培立优教育研究中心";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#login {
  background-color: #deeff0;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-content-wrap {
  color: #fff;
  position: absolute;
  font-size: 1.3vw;
  left: 6.25vw;
  bottom: 9vw;
  font-weight: bold;
}

.child-content {
  width: 23vw;
  margin-right: 15%;
}

.logo-wrap {
  margin-bottom: 2vw;

  img {
    width: 13vw;
  }
}

.login-logo {
  margin-right: 4.7vw;
}

.btn-wrap {
  cursor: pointer;
  height: 9.6875vw;
}

#login-content {
  background-image: url("../../assets/login/denglu-beijing@2x.png");
  width: 66.6vw;
  height: 41.6vw;
  background-size: 100% 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
}

.tab-img {
  width: 4.5vw;
  height: 3.4vw;
}

.user-input {
  height: 3vw;
  background-color: #f5f5f5;
  border-radius: 0.5vw;
  padding: 0.5vw 0.8vw;
  // line-height: 2vw;
  margin: 0.94vw 0 1.7vw;
}

input {
  outline: none;
  width: 100%;
  height: 2vw;
  font-size: 0.83vw;
  padding: 0;
  border: none;
  background-color: #f5f5f5;
}

.input-title {
  font-size: 0.83vw;
  font-weight: bold;
}

.select-tab {
  font-weight: bold;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  display: inline;
  font-size: 0.83vw;
  width: 5.2vw;
  height: 2.1vw;
  border-radius: 0.5vw;
  background-color: white;
  padding: 0 0.5vw;
}

#login-button {
  width: 19vw;
  height: 9.7vw;
  margin-top: -0.5vw;
}

#login-button:active {
  width: 18.5vw;
  height: 9.4vw;
}
</style>
