import dict from "../utils/dict";

export default {
  /**
   * 是否有指定的角色
   * @param customRoles
   * @param role
   * @return {boolean}
   */
  hasRole(customRoles, role) {
    return customRoles.findIndex(t => t.roleType === role) !== -1;
  },
  /**
   * 角色优先级的code
   * @param role
   * @return {number}
   */
  sortCode(role) {
    switch (role) {
      case dict.roles.Principal:
        return 4;
      case dict.roles.ClassManager:
        return 3;
      case dict.roles.MathTeacher:
        return 2;
      case dict.roles.Parents:
        return 1;
    }
  },
  /**
   * 角色名称
   * @param role
   * @return {string}
   */
  roleName(role) {
    switch (role) {
      case dict.roles.Principal:
        return "校级领导";
      case dict.roles.ClassManager:
        return "班主任";
      case dict.roles.MathTeacher:
        return "数学老师";
      case dict.roles.Parents:
        return "家长";
    }
  },
  /**
   * 权限判断
   * @param customRoles
   * @param record - 里面应该有studentId,classId
   * @return {boolean}
   */
  authority(customRoles, record) {
    let flag = false;
    for (let i = 0; i < customRoles.length; i++) {
      if (customRoles[i].roleType == dict.roles.Principal) {
        flag = true;
        break;
      } else if (customRoles[i].roleType == dict.roles.ClassManager) {
        flag = customRoles[i].entityId == record.classId;
        if (flag) break;
      } else if (customRoles[i].roleType == dict.roles.MathTeacher) {
        flag = customRoles[i].entityId == record.classId;
        if (flag) break;
      } else if (customRoles[i].roleType == dict.roles.Parents) {
        flag = customRoles[i].entityId == record.studentId;
        if (flag) break;
      }
    }

    return flag;
  },
  /**
   * 是否是学校端
   * @param customRoles
   * @return {boolean}
   */
  isSchoolTerminal(customRoles) {
    let flag = false;
    const roles = Object.values(dict.roles);
    for (let i = 0; i < customRoles.length; i++) {
      if (roles.indexOf(customRoles[i].roleType) !== -1) {
        flag = true;
        break;
      }
    }
    return flag;
  }
};
